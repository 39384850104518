<template>
  <div class="user_manage">
    <section-title title="用户管理"></section-title>
    <div class="user_manage_table">
      <div class="table_header">
        <div class="table_header_btn" @click="showDialog('create', -1)">
          <i class="iconfont icontianjiarenyuan table_header_icon"></i>
          <p class="table_header_text text_color_primary text_size_14">新增账号</p>
        </div>
      </div>
      <div class="table_main">
        <vue-scroll>
          <div class="table_main_wrapper">
            <el-table :data="userList" class="my-el-table" cell-class-name="my-el-table-cell" @cell-mouse-enter="onMouseEnter" @cell-mouse-leave="onMouserLeave">
              <el-table-column label="姓名" prop="userName"></el-table-column>
              <el-table-column label="手机号" prop="userPhone"></el-table-column>
              <el-table-column label="电子邮箱" prop="userEmail"></el-table-column>
              <el-table-column label="是否激活" prop="isActivate">
                <template slot-scope="scope">
                  <span>{{scope.row.isActivate === 0 ? '否' : '是'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="角色" prop="userType" width="120px">
                <template slot-scope="scope">
                  <span v-if="scope.row.userType === 1">操作员</span>
                  <span v-if="scope.row.userType === 2">管理员</span>
                </template>
              </el-table-column>
              <el-table-column width="120px">
                <template slot-scope="scope">
                  <el-popover
                    v-if="scope.row.userType === 1 && scope.row.showBtn"
                    popper-class="table_popover"
                    trigger="click"
                    :visible-arrow="false"
                    @show="onPopoverShow(scope.row)"
                    @hide="onPopoverHide(scope.row)">
                    <i slot="reference" class="iconfont iconshezhi table_popover_icon"></i>
                    <div class="table_popover_wrapper">
                      <div class="table_popover_item" @click="showDialog('password', scope.$index)">修改密码</div>
                      <div class="table_popover_item" @click="showDialog('edit', scope.$index)">编辑信息</div>
                      <div class="table_popover_item" @click="showDialog('delete', scope.$index)">删除账号</div>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </vue-scroll>
      </div>
    </div>

    <el-dialog title="新增账号" :visible="dialogName === 'create'" width="600px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      @close="closeDialog('createForm')">
      <el-form :model="form" ref="createForm" :rules="rules" :hide-required-asterisk="true"
        class="margin-left-20 margin-right-20" label-position="left" label-width="160px" size="small">
        <el-form-item label="手机号码" prop="userPhone">
          <el-input v-model="form.userPhone" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="userEmail">
          <el-input v-model="form.userEmail"></el-input>
        </el-form-item>
        <el-form-item label="是否激活">
          <el-switch v-model="form.isActivate" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogName = ''">取消</el-button>
        <el-button type="primary" size="small" @click="onCreateRow">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改密码" :visible="dialogName === 'password'" width="600px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      @close="closeDialog('passwordForm')">
      <el-form :model="password" ref="passwordForm" :rules="pwdRules" :hide-required-asterisk="true"
        class="margin-left-20 margin-right-20" label-position="left" label-width="160px" size="small">
        <el-form-item label="新密码" prop="new">
          <el-input type="password" v-model="password.new"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirm">
          <el-input type="password" v-model="password.confirm"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogName = ''">取消</el-button>
        <el-button type="primary" size="small" @click="onEditPassword(dialogRow)">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑信息" :visible="dialogName === 'edit'" width="600px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      @close="closeDialog('editForm')">
      <el-form :model="form" ref="editForm" :rules="rules" :hide-required-asterisk="true"
        class="margin-left-20 margin-right-20" label-position="left" label-width="160px" size="small">
        <el-form-item label="手机号码" prop="userPhone">
          <el-input :disabled="true" v-model="form.userPhone" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="userEmail">
          <el-input v-model="form.userEmail"></el-input>
        </el-form-item>
        <el-form-item label="是否激活">
          <el-switch v-model="form.isActivate" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogName = ''">取消</el-button>
        <el-button type="primary" size="small" @click="onEditRow(dialogRow)">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="删除账号" :visible="dialogName === 'delete'" width="600px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="delete_confirm">
        <i class="el-icon-warning delete_confirm_icon"></i>
        <p class="delete_confirm_text text_size_14">
          确定要删除该账号吗？账号删除后将无法找回，该账号个人空间下的图表也将被删除！可考虑
          <span class="text_color_primary text_weight_bold" @click="showDialog('edit', dialogRow)">更改激活状态</span>
        </p>
      </div>
      <div slot="footer">
        <el-button size="small" @click="dialogName = ''">取消</el-button>
        <el-button type="primary" size="small" @click="onDelteRow(dialogRow)">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '~/api'
import SHA256 from 'crypto-js/sha256'
export default {
  data () {
    return {
      userList: [], // 账号列表
      dialogName: '', // create，edit，password，delete
      dialogRow: -1, // 当前操作的账号索引，-1为新增账号
      form: {
        userPhone: '',
        password: '',
        userName: '',
        userEmail: '',
        isActivate: 0
      }, // 创建/编辑操作员表单
      rules: {
        userPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' }
        ],
        userEmail: [
          { required: true, message: '请输入电子邮箱', trigger: 'blur' },
          { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '邮箱格式错误', trigger: 'blur' }
        ]
      },
      password: {
        new: '',
        confirm: ''
      }, // 修改密码表单
      pwdRules: {
        new: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        confirm: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getUserList()
  },
  methods: {
    // 获取账号列表
    getUserList () {
      api.getUserList().then(res => {
        if (res.data.code === 0) {
          this.userList = []
          res.data.data.forEach(element => {
            element.showBtn = false
            element.showPopover = false
            this.userList.push(element)
          })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    // 打开dialog
    showDialog (name, index) {
      this.dialogRow = index
      if (index > -1) {
        // 表单内容赋值
        this.form = { ...this.userList[index] }
      } else {
        this.form = { ...this.$options.data().form }
      }
      this.dialogName = name
    },
    // 关闭时清除表单
    closeDialog (form) {
      this.$refs[form].resetFields()
    },
    // 确认新增账号
    onCreateRow () {
      this.$refs.createForm.validate(valid => {
        if (valid) {
          api.createUser({
            ...this.form,
            password: SHA256(this.form.password).toString()
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('操作员已添加')
              // 关闭dialog
              this.dialogName = ''
              this.dialogRow = -1
              // 重新获取列表
              this.getUserList()
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('网络错误！')
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    },
    // 确认修改密码
    onEditPassword (index) {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          if (this.password.new === this.password.confirm) {
            api.updateUserPassword({
              operatorId: this.userList[index].id,
              newPassword: SHA256(this.password.new).toString()
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作员密码已修改')
                this.dialogName = ''
                this.dialogRow = -1
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('网络错误！')
            })
          } else {
            this.$message.warning('两次输入的密码不一致')
            return false
          }
        } else {
          console.log('error submit')
          return false
        }
      })
    },
    // 确认修改信息
    onEditRow (index) {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          api.updateUser({
            userId: this.userList[index].id,
            userName: this.form.userName,
            userEmail: this.form.userEmail,
            isActivate: this.form.isActivate
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('操作员信息已更新')
              this.dialogName = ''
              this.dialogRow = -1
              this.getUserList()
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('网络错误！')
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    },
    // 确认删除账号
    onDelteRow (index) {
      api.removeUser({
        userId: this.userList[index].id
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作员已删除')
          this.dialogName = ''
          this.dialogRow = -1
          this.userList.splice(index, 1)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    /**
     * 控制表格内按钮显示
     */
    onMouseEnter (row, column, cell, event) {
      row.showBtn = true
    },
    onMouserLeave (row, column, cell, event) {
      if (!row.showPopover) {
        row.showBtn = false
      }
    },
    onPopoverShow (row) {
      row.showPopover = true
    },
    onPopoverHide (row) {
      row.showPopover = false
      row.showBtn = false
    }
  }
}
</script>

<style lang="scss" scoped>
.user_manage {
  width: 100%;
  height: 100%;
  padding: 30px 40px 33px 40px;
  display: flex;
  flex-direction: column;
  &_header {
    height: 30px;
  }
  &_table {
    margin-top: 20px;
    flex: 1;
    background-color: $black-color-light-1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
}
.table_header {
  position: relative;
  height: 50px;
  background-color: $black-color-light-2;
  margin-bottom: 40px;
  &_btn {
    cursor: pointer;
    position: absolute;
    top: 22px;
    left: 20px;
    display: flex;
    align-items: flex-end;
  }
  &_icon {
    width: 56px;
    height: 56px;
    color: $black-color-light-1;
    background:rgba(203,255,254,1);
    box-shadow:0px 3px 10px rgba(203,255,254,0.5);
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_text {
    margin-left: 10px;
  }
}
.table_main {
  flex: 1;
  padding-bottom: 30px;
  overflow: auto;
  &_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 20px;
  }
}
.delete_confirm {
  margin: 0 16px 0 9px;
  display: flex;
  align-items: center;
  &_icon {
    color: #E6A23C;
    font-size: 24px;
  }
  &_text {
    color: $color-text-regular;
    margin-left: 23px;
    line-height: 25px;
    & > span {
      cursor: pointer;
    }
  }
}
</style>
